<template>
<a-config-provider :locale="locale">
  <router-view/>
</a-config-provider>
</template>

<script>
import zhTW from 'ant-design-vue/es/locale/zh_TW'

export default {
  data() {
    return {
      locale: zhTW
    }
  },
  methods: {}
}
</script>

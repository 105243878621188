import { createRouter, createWebHistory, RouterView } from 'vue-router'

const routes = [
  {
    path: '',
    name: 'Portal',
    component: () => import('../pages/Portal/index.vue')
  },
  {
    path: '/env',
    name: 'Env',
    component: () => import('../pages/Env/index.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../pages/Download/index.vue')
  },

  /* Auth */
  {
    path: '/auth/login',
    name: 'AuthLogin',
    component: () => import('../pages/Auth/Login.vue'),
  },

  /* App - Common */
  {
    path: '/app',
    component: () => import('../layouts/app.vue'),
    children: [
      {
        path: 'me',
        name: 'AppMePage',
        component: () => import('../pages/App/Common/MePage/index.vue')
      },

      {
        path: '',
        name: 'AppDashboard',
        component: () => import('../pages/App/Admin/Dashboard/index.vue')
      },
      {
        path: 'members',
        name: 'AppMembers',
        component: () => import('../pages/App/Admin/Members/index.vue')
      },
      {
        path: 'assistants',
        name: 'AppAssistants',
        component: () => import('../pages/App/Admin/Assistants/index.vue')
      },
      {
        path: 'coaches',
        name: 'Appes',
        component: () => import('../pages/App/Admin/Coaches/index.vue')
      },
      {
        path: 'forms',
        name: 'AppForms',
        component: () => import('../pages/App/Admin/Forms/index.vue')
      },
      {
        path: 'forms/editor',
        name: 'AppFormsEditor',
        component: () => import('../pages/App/Admin/Forms/Editor.vue')
      },
      {
        path: 'forms/view/:form_id',
        name: 'AppFormsViewer',
        component: () => import('../pages/App/Admin/Forms/View.vue')
      },
      {
        path: 'courses',
        name: 'AppCourses',
        component: () => import('../pages/App/Common/Courses/index.vue')
      },
      {
        path: 'course/:course_id',
        name: 'AppCoursePage',
        component: () => import('../pages/App/Common/CoursePage/_id.vue')
      },
      {
        path: 'course/:course_id/settings',
        name: 'AppCourseSettingsPage',
        component: () => import('../pages/App/Common/CoursePage/Settings/index.vue')
      },
      {
        path: 'course/:course_id/announcement',
        name: 'AppCourseAnnouncementPage',
        component: () => import('../pages/App/Common/CoursePage/Announcement/index.vue')
      },
      {
        path: 'course/:course_id/instant-survey',
        name: 'AppCourseInstantSurveyPage',
        component: () => import('../pages/App/Common/CoursePage/InstantSurvey/index.vue')
      },
      {
        path: 'course/:course_id/instant-survey/:id',
        name: 'AppCourseInstantSurveyIdPage',
        component: () => import('../pages/App/Common/CoursePage/InstantSurvey/_id/index.vue'),
        children: [
          {
            path: 'editor',
            name: 'AppCourseInstantSurveyIdPageEditor',
            component: () => import('../pages/App/Common/CoursePage/InstantSurvey/_id/Editor.vue')
          },
          {
            path: 'statistic',
            name: 'AppCourseInstantSurveyIdPageStatistic',
            component: () => import('../pages/App/Common/CoursePage/InstantSurvey/_id/Statistic.vue')
          },
          {
            path: 'setting',
            name: 'AppCourseInstantSurveyIdPageSetting',
            component: () => import('../pages/App/Common/CoursePage/InstantSurvey/_id/Setting.vue')
          }
        ]
      },
      {
        path: 'course/:course_id/push-messages',
        name: 'AppCoursePushMessages',
        component: () => import('../pages/App/Common/CoursePage/PushMessages/index.vue')
      },
      {
        path: 'course/:course_id/lession/:lession_id',
        name: 'AppCourseLessionPage',
        component: () => import('../pages/App/Common/LessionPage/_id.vue')
      },

      // Material
      {
        path: 'materials',
        name: 'AppMaterials',
        component: () => import('../pages/App/Common/Materials/index.vue')
      },
      {
        path: 'materials/:material_id',
        name: 'AppMaterialPage',
        component: () => import('../pages/App/Common/MaterialPage/_id.vue')
      },
      
      // Forum
      {
        path: 'forum',
        name: 'AppForum',
        component: () => import('../pages/App/Common/Forum/index.vue')
      },
      {
        path: 'forum/editor',
        name: 'AppForumEditor',
        component: () => import('../pages/App/Common/Forum/Editor/index.vue')
      },
      {
        path: `forum/article/:article_id`,
        name: 'AppForumArticle',
        component: () => import('../pages/App/Common/Forum/Article.vue')
      }
    ]
  },
  
  /* Form */
  {
    path: '/form/fill/:code',
    name: 'FormFillPage',
    component: () => import('../pages/App/Common/Form/Fill.vue'),
  },

  /* Liff */
  {
    path: '/liff',
    component: RouterView,
    children: [
      {
        path: 'auth',
        name: 'LiffAuth',
        component: () => import('../pages/Liff/Auth.vue')
      },
      {
        path: 'my-courses/redirect',
        name: 'LiffMyCoursesRedirect',
        component: () => import('../pages/Liff/MyCourses/LiffRedirect.vue')
      },
      {
        path: 'my-courses',
        name: 'LiffMyCourses',
        component: () => import('../pages/Liff/MyCourses/index.vue')
      },
      {
        path: 'course/:course_id',
        component: () => import('../pages/Liff/CoursePage/index.vue'),
        children: [
          {
            path: '',
            name: 'LiffCourseList',
            component: () => import('../pages/Liff/CoursePage/List.vue')
          },
          {
            path: 'announcements',
            name: 'LiffCourseAnnouncements',
            component: () => import('../pages/Liff/CoursePage/Announcements.vue')
          },
          {
            path: 'instant-survey-list',
            name: 'LiffCourseInstantSurveyList',
            component: () => import('../pages/Liff/CoursePage/InstantSurveyList.vue')
          },
          {
            path: 'album',
            name: 'LiffCourseAlbum',
            component: () => import('../pages/Liff/CoursePage/Album.vue')
          }
        ]
      },
      {
        path: 'course/:course_id/lession/:lession_id',
        name: 'LiffLessionPage',
        component: () => import('../pages/Liff/LessionPage/index.vue')
      },
      {
        path: 'course/:course_id/rollcall',
        name: 'LiffRollcallPage',
        component: () => import('../pages/Liff/RollcallPage.vue')
      },
      {
        path: 'course/:course_id/instant-survey/:id',
        name: 'LiffInstantSurveyPage',
        component: () => import('../pages/Liff/InstantSurveyPage/index.vue')
      },
      {
        path: 'instant-survey/redirect',
        name: 'LiffInstantSurveyLiffRedirect',
        component: () => import('../pages/Liff/InstantSurveyPage/LiffRedirect.vue')
      },
      {
        path: 'instant-survey/:course_id/:id',
        name: 'LiffInstantSurveyLiffPage',
        component: () => import('../pages/Liff/InstantSurveyPage/LiffPage.vue')
      },

      // Richmenu: 運動紀錄
      {
        path: 'me',
        name: 'LiffMeIndex',
        component: () => import('../pages/Liff/Me/index.vue')
      },
      {
        path: 'me/setting',
        name: 'LiffMeSetting',
        component: () => import('../pages/Liff/Me/Setting.vue')
      },
      
      // FIXME: 連接心率帶
      // {
      //   path: '/liff/bluetooth',
      //   name: 'LiffBluetooth',
      //   component: () => import('../pages/Liff/Bluetooth.vue')
      // },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import pinia from "./store";
import "./registerServiceWorker";

// scss
import "/src/assets/scss/main.scss";
import "ant-design-vue/dist/antd.css";
import "@mdi/font/css/materialdesignicons.css";

const app = createApp(App);

// close productionTip
app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

app.use(Antd);
app.use(pinia);
app.use(router);

app.mount("#app");

/* Api */
const getSubscriptionPublicKey = async () => {
  const response = await fetch(
    "https://letwecare-sports.com/api/v1/notification/subscription"
  );
  const data = await response.json();
  console.log(data.result);
  return data.result.public_key;
};

/* Utils */
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/* Notification Funcs */
function askPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
}

async function userSubscribe() {
  const publicKey = await getSubscriptionPublicKey();

  // 3. 註冊 serviceWorker
  const register = await navigator.serviceWorker.register(
    "/service-worker.js",
    {
      scope: "/",
    }
  );
  console.log("Registration succeeded. Scope is " + register.scope);

  register.showNotification("test");

  // 4. 使用 PushManager 訂閱推播
  const subscription = await register.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicKey),
  });
  console.log(subscription);

  // 5. 向 Sever 發送訂閱
  let data = {
    token: subscription,
  };
  await fetch("https://letwecare-sports.com/api/v1/notification/subscribe", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/* Service Worker */
if ("serviceWorker" in navigator) {
  console.log("Service Worker is supported");

  // TODO: 暫時不實現推播功能
  // askPermission();
  // userSubscribe();
} else {
  console.log("Service Worker is not supported");
}
